import { useState, useEffect, Fragment } from 'react';
// import ComboBox from 'react-responsive-combo-box';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
    getAllDownloadViews,
    getUserCountries,
    getUserCompanies,
    getCreateZipAll,
    getUserZips,
} from '../services/backend';
import styled from 'styled-components';
import {
    headerColor,
    textColor,
    backgroundGrey,
    buttonColor,
    buttonHoverColor,
    buttonHoverTextColor,
    bannerColor,
    buttonTextColor,
} from '../style/colors';
import { useDownloadContext } from '../contexts/DownloadContext';

const HeaderText = styled.div`
    position: absolute;
    top: 0;
    height: 65;
    left: calc((80vw / 2));
    background: ${bannerColor};
    margin-top: 5px;
    overflow: auto;
    color: ${headerColor};
    font-size: 24px;
    font-weight: bold;
    margin-top: 20px;
    margin-left: 20px;
    z-index: 2;
`;

export const ContentContainer = styled.div`
    clear: both;
    background: ${bannerColor};
    width=100%;
`;


export const BodyRight = styled.div`
    justify-content: center;
    // width: 400px;
    height: 100%;
    float: left;
    background: ${backgroundGrey};
    padding-left: 20px;
    padding-top: 10px;
    // margin-top: 5px;
`;
export const BodyLeft = styled.div`
    justify-content: center;
    width: 480px;
    height: 100%;
    float: left;
    background: ${backgroundGrey};
    padding-left: 40px;
    padding-top: 10px;
    // margin-top: 5px;
`;

export const TextArea = styled.div`
    color: ${textColor};
    font-size: 18px;
    float: left;
    width: 440px;
    padding-left: 0px;
    // font-weight: bold;
`;

export const PushButton = styled.button`
    color: ${buttonTextColor};
    background: ${buttonColor};
    font-size: 20px;
    cursor: pointer;
    border-radius: 12px;
    height: 45px;

    &:hover {
        background: ${buttonHoverColor};
        color: ${buttonHoverTextColor};
    }
`;
export const LabelText = styled.div`
    width: 150px;
    float: left;
    color: ${textColor};
    font-size: 20px;
    font-weight: bold;
`;
export const LabelRightText = styled.div`
    // width: 150px;
    float: left;
    color: ${textColor};
    font-size: 20px;
    font-weight: bold;
`;
export const FromText = styled.div`
    width: 50px;
    float: left;
    color: ${textColor};
    font-size: 20px;
    padding-right: 5px;
`;
export const NewLineText = styled.div`
    width: 40px;
    clear: both;
    color: ${textColor};
    font-size: 20px;
    padding-left: 5px;
    padding-right: 5px;
`;

export const ComboSelect = styled.select`
    width: 320px;
    color: ${textColor};
    font-size: 21px;
    margin-bottom: 10px;
`;

export const CountryPart = styled.div`
    color: ${textColor};
    font-size: 18px;
    float: left;
    width: 350px;
    font-weight: bold;
`;

const Downloads = () => {
    const [countries, setCountries] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [userzips, setUserzips] = useState([]);
    const [downloadurl, setDownloadurl] = useState([]);
    const trialtypes = [
        {
            trial_name: 'Core trial',
        },
        {
            trial_name: 'Satellite trial',
        },
    ];
    const [downloadviews, setDownloadViews] = useState([
        {
            shortname: '',
            view_caption: '',
            view_description: '',
            index: 0,
        },
    ]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const { download, dispatch, setCompany } = useDownloadContext();

    const [selectedCountry, setSelectedCountry] = useState(''); // download.country
    const [selectedCompany, setSelectedCompany] = useState(''); // download.company
    const [selectedTrialName, setSelectedTrialName] = useState(
        'Satellite trial'
    );
    const [selectedDownloadIndex, setSelectedDownloadIndex] = useState(-1);

    const handleSetCountry = (country) => {
        // DownloadContext global state, direct dispatch, first paramter is the state object, second parameter is the action object
        dispatch({
            type: 'actCOUNTRY',
            payload: { country },
        });
        setSelectedCountry(country);
        document.getElementById("getzip").hidden = true;
        document.getElementById("dozip").hidden = false;
    };
    const handleSetCompany = (company) => {
        // DownloadContext global state, indirect dispatch via Action creators function setCompany

        setCompany(company);
        setSelectedCompany(company);
    };
    const handleSetTrialName = (trialname) => {
        // trialname not by DownloadContext global state
        // setTrialType(TrialName);
        handleSetDownloadIndex(-1, false);
        setSelectedTrialName(trialname);
        document.getElementById("country").selectedIndex = 0;

        document.getElementById("getzip").hidden = true;
        document.getElementById("dozip").hidden = false;
    };
    const handleSetDownloadIndex = (index, update_screen) => {
        setSelectedDownloadIndex(index);
        if (update_screen) {
            const divDescription = document.getElementById('ta');
            divDescription.innerHTML = downloadviews[index].view_description;
        }
    };
    const handleZipAll = () => {
        document.body.style.cursor = 'wait';
        document.getElementById("dozip").hidden = true;
        getCreateZipAll(selectedTrialName, selectedCountry)
        .then((data) => {
            document.getElementById("getzip").hidden = false;
            document.body.style.cursor = 'auto';
            setDownloadurl(data[0].url);
            getUserZips()
            .then((data) => {
                setUserzips(data);
            })
            .catch((error) => {
                console.error(error);
            });
        })
        .catch((error) => {
            document.getElementById("dozip").hidden = false;
            document.body.style.cursor = 'auto';
            setDownloadurl("");
            console.error(error);
        });
    };

    useEffect(() => {
        if (selectedCountry.length === 0) {
            // only at startup
            setSelectedTrialName(trialtypes[0].trial_name);
            // not: startDate.setFullYear(2020); startDate.setMonth(0); startDate.setDate(1);
            startDate.setFullYear(endDate.getFullYear() - 1);
            getUserZips()
            .then((data) => {
                setUserzips(data);
            })
            .catch((error) => {
                console.error(error);
            });
        }
        if (selectedDownloadIndex === -1) {
            if (selectedTrialName.length > 0) {
                // every time selectedTrialType changes
                getUserCountries(selectedTrialName)
                    .then((data) => {
                        setCountries(data);
                        handleSetCountry(data[0].country); // set first value
                    })
                    .catch((error) => {
                        console.error(error);
                    });
                getAllDownloadViews(selectedTrialName)
                    .then((data) => {
                        setDownloadViews(data);
                        handleSetDownloadIndex(0, false); // set first value
                        const tempDescription = document.getElementById('ta');
                        tempDescription.innerHTML = data[0].view_description;
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        }
        if (selectedCountry.length > 0) {
            // every time selectedCountry changes

            getUserCompanies(selectedCountry, selectedTrialName)
                .then((data) => {
                    setCompanies(data);
                    handleSetCompany(
                        data[document.getElementById('company').selectedIndex]
                            .company
                    ); // set first value
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [selectedCountry, selectedTrialName]); //

    return (
        <ContentContainer>
            <HeaderText>Select a dataset to download</HeaderText>
            <BodyLeft>
                <LabelText>Trial type: </LabelText>
                <ComboSelect
                    name="TrialName"
                    id="TrialName"
                    onChange={(option) =>
                        handleSetTrialName(option.target.value)
                    }
                >
                    {trialtypes.map((TrialName) => {
                        return (
                            <option value={TrialName.trial_name}>
                                {TrialName.trial_name}
                            </option>
                        );
                    })}
                </ComboSelect>
                <br />
                <LabelText>Country: </LabelText>
                <ComboSelect
                    name="country"
                    id="country"
                    onChange={(option) => handleSetCountry(option.target.value)}
                >
                    {countries.map((country) => {
                        return (
                            <option value={country.country}>
                                {country.country}
                            </option>
                        );
                    })}
                </ComboSelect>
                <NewLineText>&nbsp;</NewLineText>
                <PushButton id="dozip" onClick={() => handleZipAll()}>&nbsp;Zip all {selectedTrialName}s in {download.country}&nbsp;</PushButton>
                <Fragment>
                    <a
                        href={`${downloadurl}`}
                        rel={`noreferrer`}
                    >
                        <PushButton id="getzip" hidden="true">&nbsp;Download zip with all {selectedTrialName}s in {download.country}&nbsp;</PushButton>
                    </a>{' '}
                </Fragment>
                <NewLineText>&nbsp;</NewLineText>                
                <LabelText>Company: </LabelText>
                <ComboSelect
                    name="company"
                    id="company"
                    onChange={(option) => handleSetCompany(option.target.value)}
                >
                    {companies.map((company) => {
                        return (
                            <option value={company.company}>
                                {company.company}
                            </option>
                        );
                    })}
                </ComboSelect>
                <br />
                <LabelText>Dataset: </LabelText>
                <ComboSelect
                    name="downloadview"
                    id="downloadview"
                    onChange={(option) =>
                        handleSetDownloadIndex(option.target.value, true)
                    }
                >
                    {downloadviews.map((downloadview) => {
                        return (
                            <option value={downloadview.index}>
                                {downloadview.view_caption}
                            </option>
                        );
                    })}
                </ComboSelect>
                <br />               
                <TextArea id="ta"></TextArea>
                
                <NewLineText>&nbsp;</NewLineText> 
                <LabelText>Date: </LabelText>
                <FromText>
                    from:
                    <br />
                    to:
                </FromText>
                <LabelText>
                    <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="yyyy-MM-dd"
                        maxDate={endDate}
                    />
                    <br />
                    <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        dateFormat="yyyy-MM-dd"
                        minDate={startDate}
                        maxDate={new Date()}
                    />
                </LabelText>
                <NewLineText>&nbsp;</NewLineText>
                <Fragment>
                    <a
                        href={`/cocoasoilsserver/companycsv/${
                            downloadviews[
                                selectedDownloadIndex < 0
                                    ? 0
                                    : selectedDownloadIndex
                            ].shortname
                        }?par1=${
                            download.country
                        }&par0=${selectedCompany}&par3=${startDate
                            .toISOString()
                            .substring(
                                0,
                                10
                            )}&like3=startdate&par4=${endDate
                            .toISOString()
                            .substring(0, 10)}&like4=enddate`}
                        target={`_blank`}
                        rel={`noreferrer`}
                    >
                        <PushButton>&nbsp;Download as CSV&nbsp;</PushButton>
                    </a>{' '}
                    &nbsp;
                    <a
                        href={`/cocoasoilsserver/companyjson/${
                            downloadviews[
                                selectedDownloadIndex < 0
                                    ? 0
                                    : selectedDownloadIndex
                            ].shortname
                        }?par1=${selectedCountry}&par0=${
                            download.company
                        }&par3=${startDate
                            .toISOString()
                            .substring(
                                0,
                                10
                            )}&like3=startdate&par4=${endDate
                            .toISOString()
                            .substring(0, 10)}&like4=enddate`}
                        target={`_blank`}
                        rel={`noreferrer`}
                    >
                        <PushButton>&nbsp;Download as JSON&nbsp;</PushButton>
                    </a>{' '}
                </Fragment>
            </BodyLeft>
            <BodyRight>
                
            <LabelRightText>Download recent created zipfiles: </LabelRightText>
            <NewLineText>&nbsp;</NewLineText>
            {userzips.map((UserZip) => {
                        return (
                            <Fragment><a 
                            href={UserZip.url}
                            target={`_blank`}
                            rel={`noreferrer`}>Export of {UserZip.country} {UserZip.trialtype} created on {UserZip.created}</a><br /></Fragment>
                        );
                    })}
            </BodyRight>
        </ContentContainer>
    );
};

export default Downloads;

/* selectedCountry, selectedCompany are local state
   download.company, download.company are global state
   Both work, as you can see in the <a herf=  part
/> */
