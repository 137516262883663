import TileWMSSource from 'ol/source/TileWMS';

const defaultOptions = {
    params: {
        TILED: true,
        serverType: 'geoserver',
    },
};

function tileWMS(options) {
    const { params, ...restOptions } = options;
    const mergedOptions = Object.assign(
        {},
        defaultOptions,
        { params: Object.assign({}, defaultOptions.params, options.params) },
        restOptions
    );
    return new TileWMSSource(mergedOptions);
}

export default tileWMS;
