import { useContext, useEffect } from 'react';
import { MousePosition } from 'ol/control';
import { toLonLat } from 'ol/proj';
import MapContext from '../Map/MapContext';

const MousePositionControl = () => {
    const { map } = useContext(MapContext);

    useEffect(() => {
        if (!map) return;

        let mousePositionControl = new MousePosition({
            coordinateFormat: (coords) =>
                toLonLat(coords)
                    .map((c) => c.toFixed(4))
                    .join(', '),
        });

        map.controls.push(mousePositionControl);

        return () => map.controls.remove(mousePositionControl);
    }, [map]);

    return null;
};

export default MousePositionControl;
