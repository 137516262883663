// import './App.css';
import styled from 'styled-components';
import { bannerColor } from './style/colors';

import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import HomeView from './views/Home';
import ExploreView from './views/Explore';
// import DashboardView from './views/Dashboard';
import DownloadsView from './views/Downloads';
import OntologyView from './views/Ontology';
import img from './CocoaSoils_small.png';
import { DownloadProvider } from './contexts/DownloadContext'; // put it inside App.js in case you want to shar eit between components
/* import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom"; */

const ContentContainer = styled.div`
    width: 100%;
    height: 90px;
    background: ${bannerColor};
    font-size: 20px;
`;
const LabelText = styled.div`
    float: left;
    font-size: 18px;
    background: ${bannerColor};
    width: 50%;
    height: 90px;
`;
const MenuText = styled.div`
    float: right;
    background: ${bannerColor};
    text-algin: right:
    width: 50%;
    height: 90px;
    font-size: 16px;
`;

export default function App() {
    return (
        <DownloadProvider>
            <Router>
                <ContentContainer>
                    <LabelText>
                        <img src={img} alt="logo" />
                    </LabelText>
                    <MenuText>
                        <br />
                        <br />
                        <Link to="/">Home</Link>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Link to="/opendata">Open data & graph</Link>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        {/* Link to="/dashboard">Dashboard< -- /Link> &nbsp;&nbsp;&nbsp;&nbsp; */}
                        <Link to="/consortiumdata">Consortium data</Link>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Link to="/ontology">Ontology</Link>
                        &nbsp;&nbsp;&nbsp;
                    </MenuText>

                    {/* A <Routes> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
                    <Routes>
                        <Route exact path="/" element={<HomeView />} />
                        <Route
                            exact
                            path="/opendata"
                            element={<ExploreView />}
                        />
                        {/* <Route
                            exact
                            path="/dashboard"
                            element={<DashboardView />} 
                        />*/}
                        <Route
                            exact
                            path="/consortiumdata"
                            element={<DownloadsView />}
                        />
                        <Route
                            exact
                            path="/ontology"
                            element={<OntologyView />}
                        />
                    </Routes>
                </ContentContainer>
            </Router>
        </DownloadProvider>
    );
}
