import WMTS, { optionsFromCapabilities } from 'ol/source/WMTS';
import WMTSCapabilities from 'ol/format/WMTSCapabilities';
import { defaultMapProjection } from '../config';

const capabilityParser = new WMTSCapabilities();

const getCapabilities = async (url, layer) => {
    const capabilities = await fetch(url).then(function (response) {
        return response.text();
    });
    const parsedResult = capabilityParser.read(capabilities);
    const options = optionsFromCapabilities(parsedResult, {
        layer,
        matrixSet: defaultMapProjection,
    });
    return options;
};

export const getWMTS = async (getCapabilitiesUrl, layer) => {
    const options = await getCapabilities(getCapabilitiesUrl, layer);
    return new WMTS(options);
};

export default getWMTS;
