import { createContext, useReducer, useContext } from 'react';

const DownloadContext = createContext();

const initialState = {
    country: 'Cote d Ivoir',
    company: 'Cargill',
};
const reducer = (state, action) => {
    switch (action.type) {
        case 'actCOUNTRY': {
            const countryname = action.payload.country;
            return {
                ...state,
                country: countryname,
            };
        }
        case 'actCOMPANY':
            return {
                ...state,
                company: action.payload.company,
            };
        default:
            return state;
    }
};

// Action creators
// export const setCountry = (country) => ({
//     type: 'actCOUNTRY',
//     payload: { country },
// });

// export const setCompany = (company) => ({
//     type: 'actCOMPANY',
//     payload: { company },
// });

export const DownloadProvider = (props) => {
    const [download, dispatch] = useReducer(reducer, initialState);

    const setCompany = (company) => {
        dispatch({
            type: 'actCOMPANY',
            payload: { company },
        });
    };

    return (
        <DownloadContext.Provider
            value={{
                download,
                dispatch,
                setCompany,
            }}
            {...props}
        />
    );
};

const useDownloadContext = () => useContext(DownloadContext);

export { useDownloadContext };
