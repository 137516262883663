import { useState, useEffect, Fragment } from 'react';
import styled from 'styled-components';
import {
    bannerColor,
    textColor,
    headerColor,
    buttonColor,
    buttonHoverColor,
    buttonHoverTextColor,
    buttonTextColor,
} from '../style/colors';

export const SupsersetContainer = styled.iframe`
    height: 500px;
    width: calc(100vw - 5px);
    overflow: hidden;
`;

const HeaderText = styled.div`
    position: absolute;
    top: 0;
    height: 65;
    left: calc((80vw / 2));
    color: ${headerColor};
    background: ${bannerColor};
    overflow: auto;
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 24px;
    font-weight: bold;
    z-index: 2;
`;
const ContentContainer = styled.div`
    background: ${bannerColor};
    width: 100%;
    height: 100%;
`;

export const HorizontalContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    padding-top: 20px;
`;

export const BulletContainer = styled.div`
    padding-right: 50px;
    flex-direction: row;
    font-size: 18px;
    font-weight: bold;
`;

export const TextArea = styled.div`
    color: ${textColor};
    padding-right: 50px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    font-size: 18px;
`;

export const PushButton = styled.button`
    color: ${buttonTextColor};
    background: ${buttonColor};
    font-size: 20px;
    cursor: pointer;
    border-radius: 12px;
    height: 45px;

    &:hover {
        background: ${buttonHoverColor};
        color: ${buttonHoverTextColor};
    }
`;

export const NewLineText = styled.div`
    width: 40px;
    clear: both;
    color: ${textColor};
    font-size: 20px;
    padding-left: 5px;
    padding-right: 5px;
`;

const Explore = () => {
    const [graphnumber, setGraphnumber] = useState([21]);
    const [supersetUrl, setSupersetUrl] = useState([]);

    const showGraph = (graph_nr) => {
        setGraphnumber(graph_nr);
    };

    useEffect(() => {
        setSupersetUrl(
            'https://superset.containers.wur.nl/superset/explore/?r=' +
                graphnumber +
                '&standalone=1&height=600'
        );
        if (graphnumber === 23) {
            document.getElementById('s21_22').checked = true;
            document.getElementById('s22_23').checked = false;
        } else {
            document.getElementById('s21_22').checked = false;
            document.getElementById('s22_23').checked = true;
        }
    }, [graphnumber]);

    return (
        <ContentContainer>
            <HeaderText>Open data & graph</HeaderText>
            <HorizontalContainer>
                <BulletContainer>
                    <label>Select season: </label>
                    <input
                        type="radio"
                        id="s21_22"
                        name="season"
                        value="23"
                        onClick={() => showGraph(23)}
                    />
                    {' 2021/22 '}
                    <input
                        type="radio"
                        id="s22_23"
                        name="season"
                        value="21"
                        onClick={() => showGraph(21)}
                    />
                    {' 2022/23 '}
                </BulletContainer>
                <TextArea>
                    T1 = current farmer practice
                    <br />
                    T2 = weeding + pruning + insecticide application + fungicide
                    application (no fertiliser application)
                    <br />
                    T3 = weeding + pruning + insecticide application + fungicide
                    application + current national fertiliser recommendation
                    <br />
                    T4 = weeding + pruning + insecticide application + fungicide
                    application + offtake model recommendation
                </TextArea>
            </HorizontalContainer>

            <SupsersetContainer
                src={supersetUrl}
                title="Yield graph"
                scrolling="no"
            ></SupsersetContainer>

            <HorizontalContainer>
                <Fragment>
                    <a
                        href={`/cocoasoilsserver/csv/averageyield`}
                        target={`_blank`}
                        rel={`noreferrer`}
                    >
                        <PushButton>
                            &nbsp;Download Data as CSV&nbsp;
                        </PushButton>
                    </a>{' '}
                    &nbsp;
                    <a
                        href={`/cocoasoilsserver/json/averageyield`}
                        target={`_blank`}
                        rel={`noreferrer`}
                    >
                        <PushButton>
                            &nbsp;Download Data as JSON&nbsp;
                        </PushButton>
                    </a>{' '}
                </Fragment>
            </HorizontalContainer>
        </ContentContainer>
    );
};

export default Explore;

// style="height:200px;width:300px"      /* global state available in case commentsare removed   {download.country} {download.company} */
