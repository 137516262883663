export function verifyuser() {
    return new Promise((resolve, reject) => {
        fetch('/cocoasoilsserver/verifyuser')
            .then((response) => response.json())
            .then((data) => resolve(data))
            .catch(reject);
    });
}

export function getAllCountries() {
    return new Promise((resolve, reject) => {
        fetch('/cocoasoilsserver/json/countries')
            .then((response) => response.json())
            .then((data) => resolve(data.items))
            .catch(reject);
    });
}

export function getUserCountries(trialname) {
    return new Promise((resolve, reject) => {
        fetch(`/cocoasoilsserver/companycountries?trialtype=${trialname}`)
            .then((response) => response.json())
            .then((data) => resolve(data.items))
            .catch(reject);
    });
}

export function getCreateZipAll(trialname, countryname) {
    return new Promise((resolve, reject) => {
        fetch(`/cocoasoilsserver/downloadallusercompanies?trialtype=${trialname}&par2=${countryname}`)
            .then((response) => response.json())
            .then((data) => resolve(data.items))
            .catch(reject);
    });
}

export function getUserZips() {
    return new Promise((resolve, reject)  => {
        fetch(`/cocoasoilsserver/userzip`)
            .then((response) => response.json())
            .then((data) => resolve(data.items))
            .catch(reject);
    });
}

export function getUserCompanies(country, trialname) {
    return new Promise((resolve, reject) => {
        fetch(
            `/cocoasoilsserver/usercompanies?par2=${country}&trialtype=${trialname}`
        )
            .then((response) => response.json())
            .then((data) => resolve(data.items))
            .catch(reject);
    });
}

export function getMapLayers() {
    return new Promise((resolve, reject) => {
        fetch('/cocoasoilsserver/json/maplayers')
            .then((response) => response.json())
            .then((data) => resolve(data.items))
            .catch(reject);
    });
}

export function getAllDownloadViews(trialname) {
    return new Promise((resolve, reject) => {
        fetch(`/cocoasoilsserver/json/views_description?par1=${trialname}`)
            .then((response) => response.json())
            .then((data) => resolve(data.items))
            .catch(reject);
    });
}
