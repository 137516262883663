import styled from 'styled-components';
import { bannerColor, headerColor } from '../style/colors';

// import { useDownloadContext } from '../contexts/DownloadContext';
export const IFrameContainer = styled.iframe`
    height: calc(100vh - 100px);
    width: calc(100vw - 5px);
`;

const HeaderText = styled.div`
    position: absolute;
    top: 0;
    height: 65;
    left: calc((80vw / 2));
    color: ${headerColor};
    background: ${bannerColor};
    overflow: auto;
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 24px;
    font-weight: bold;
    z-index: 2;
`;
const ContentContainer = styled.div`
    background: ${bannerColor};
    width: 100%;
    height: 100%;
`;

const Ontology = () => {
    // const { download } = useDownloadContext();
    const webvowlUrl =
        'https://cocoasoils.containers.wur.nl/webvowl/#url=https://geoserver-data.wenr.containers.wur.nl/projectfilesupport/cocoasoils/Cocoa_Onotology_v03title.json';

    return (
        <ContentContainer>
            <HeaderText>CocoaSoils Ontology</HeaderText>
            <IFrameContainer
                src={webvowlUrl}
                title="CocoaSoils Ontology"
            ></IFrameContainer>
        </ContentContainer>
    );
};

export default Ontology;

// style="height:200px;width:300px"      /* global state available in case commentsare removed   {download.country} {download.company} */
